<template>
    <div class="_pwd_form">
      <div>
        <label>&nbsp;&nbsp;&nbsp; 新密码：</label>
        <el-input v-model="pwd1"></el-input>
      </div>
      <div>
        <label>校验密码：</label>
        <el-input v-model="pwdForm.user_pwd"></el-input>
      </div>
    </div>
</template>

<script>
export default {
  data: function () {
    return {
      pwd1: ''
    }
  },
  props: {
    pwdForm: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  created () {
    this.pwd1 = ''
    this.pwdForm.user_pwd = ''
  },
  methods: {
    validateForm () {
      let flag = true
      if (this.pwd1 !== this.pwdForm.user_pwd) {
        this.$message.error('2次密码输入不一致')
        flag = false
      }
      return flag
    }
  }
}
</script>

<style scoped>
._pwd_form{
  display: flex;
  flex-direction: column;
  justify-content: start;
}
._pwd_form div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
     margin-bottom: 10px;
}
._pwd_form div > label{
    width: 100px;
}
</style>
