<template>
    <div class="content">
         <el-button type="primary" class="el-icon-plus" style="margin-right:20px" @click="AddClick"> 新增</el-button>
         <el-table :data='SerUserDataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
              <el-table-column align="center" prop="user_name" label="姓名"></el-table-column>
                <el-table-column align="center" prop="user_login_id" label="登录账号"></el-table-column>
                <el-table-column align="center" prop="user_login_time" label="最近登录时间">
                    <template slot-scope="scope">
                        {{FormateDate(scope.row.user_login_time)}}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="user_phone" label="联系方式"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <div class="link_btn">
                            <el-link type="primary" @click="Edit(scope.row)">编辑</el-link>
                            <el-link type="warning" @click="EditPwd(scope.row)">修改密码</el-link>
                            <el-link type="danger" @click="Del(scope.row)">删除</el-link>
                        </div>
                    </template>
                </el-table-column>
         </el-table>

        <el-dialog :title="title" append-to-body :visible.sync="dialogVisible" width='30%'>
            <el-form :model="userForm" :rules="rules" ref="userForm" label-width="100px" size='medium'>
                <el-form-item label="姓名" prop="user_name">
                    <el-input v-model="userForm.user_name"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="user_login_id">
                    <el-input v-model="userForm.user_login_id"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input v-model="userForm.user_phone"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog v-if="pwdDialogVisible" title="修改密码" append-to-body width="50%" :visible.sync="pwdDialogVisible" >
            <PwdForm ref='childRules' :pwdForm='pwdForm'/>
            <span slot="footer" class="dialog-footer">
              <el-button @click="pwdDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="Ok">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'
import PwdForm from '../../components/pwd.vue'

export default {
  data: function () {
    return {
      SerUserDataList: [],
      dialogVisible: false,
      pwdDialogVisible: false,
      title: '',
      userForm: {
        user_id: 0,
        user_name: '',
        user_login_id: '',
        user_phone: '',
        user_sp_id: null, // 确定操作员所属服务商、运营商或车场、商家的id
        user_type: 0
      },
      pwdForm: {
        user_id: 0,
        user_pwd: ''
      },
      rules: {
        user_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        user_login_id: [{ required: true, message: '请输入用户名', trigger: 'blur' }]
      }
    }
  },
  props: {
    beglongId: Number,
    userType: Number
  },
  created () {
    this.GetUserDataList()
  },
  components: {
    PwdForm
  },
  computed: {
    FormateDate () {
      return function (time) {
        var t = ''
        if (time !== undefined && time !== null && time !== '') {
          t = util.FormateDate(time, 2)
        }
        return t
      }
    }
  },
  methods: {
    GetUserDataList () {
      util.Get('user/getuserdatalist?id='+this.beglongId+'&userType='+this.userType).then(res => {
        if (res.rpStatus === 10000) {
          this.SerUserDataList = res.list
        }
      })
    },
    AddClick () {
      this.userForm.user_id = 0
      this.userForm.user_name = ''
      this.userForm.user_login_id = ''
      this.userForm.user_phone = ''
      this.userForm.user_sp_id = this.beglongId
      this.userForm.user_type = this.userType
      this.dialogVisible = true
      this.title = '新增'
    },
    Edit (row) {
      this.dialogVisible = true
      this.title = '修改'
      this.userForm = JSON.parse(JSON.stringify(row))
      this.userForm.user_sp_id = this.beglongId
    },
    EditPwd (row) {
      this.pwdDialogVisible = true
      this.pwdForm.user_pwd = ''
      this.pwdForm.user_id = row.user_id
    },
    Ok () {
      const flag = this.$refs.childRules.validateForm()
      if (flag) {
        util.Post('user/editpwd', this.pwdForm).then(res => {
          if (res.rpStatus === 10000) {
            this.$message({
              message: '修改密码成功',
              type: 'success'
            })
            this.pwdDialogVisible = false
          }
        })
      }
    },
    submit () {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          util.Post('user/edit', this.userForm).then(res => {
            if (res.rpStatus === 10000) {
              this.GetUserDataList()
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },
    Del (row) {
      this.$confirm('确定删除人员“' + row.user_name + '”？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        util.Get('user/del?id=' + row.user_id).then(res => {
          if (res.rpStatus === 10000) { this.GetUserDataList() } else { this.$message.error(util.ErrorMsg(res.rpStatus)) }
        })
      }).catch(e => e)
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction:column;
    align-items: flex-start;
}
.el-table{
    margin-top:10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
</style>
